@import '@/_styles/import';
$root: '.WhatsIncludedModal';

.Flyout__backdrop {
  z-index: $z-index-flyout;
}

#{$root} {
  &__background {
    background: $WhatsIncluded-gradient-end-point;
    background: linear-gradient(0deg, $WhatsIncluded-gradient-start-point 0%, $WhatsIncluded-gradient-end-point 100%);
    border-radius: rem(8);
  }

  &__flyoutContent {
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%) !important;

    width: 90% !important;
    height: 95% !important;
    max-height: 95% !important;

    background-color: transparent !important;

    @include media-breakpoint-up(md) {
      width: 60% !important;
    }

    @include media-breakpoint-up(xl) {
      width: 40% !important;
    }
  }

  &__content {
    padding-right: rem(40) !important;
    padding-left: rem(40) !important;
  }

  &__close {
    cursor: pointer;

    position: absolute;
    z-index: 1000;
    top: rem(20);
    right: rem(20);

    align-self: flex-end;

    width: rem(37);
    margin-bottom: rem(20);
    padding: 0 rem(10);

    background-color: $black-003;
    border: 0;
    border-radius: rem(20);

    @include font($size: rem(28), $weight: 300, $line-height: initial, $color: $white);

    &:focus-visible {
      outline-color: $white;
    }
  }

  &__close:hover {
    color: $gray-600;
  }

  &__header {
    margin-top: rem(10);
    margin-bottom: rem(30);

    font-size: rem(17);
    font-weight: bold;
    color: $white;
  }

  &__list {
    display: flex;
    flex-direction: column;
    width: 90%;
    margin: auto;

    &__item {
      display: flex;
      align-items: center;
      align-self: center;

      width: rem(250);
      margin-bottom: rem(20);

      @include media-breakpoint-up(sm) {
        width: rem(360);
      }

      &__image {
        align-self: baseline;
        width: rem(35);
        margin-right: rem(22);
        filter: brightness(2);
      }

      &__text {
        display: flex;
        flex-direction: column;

        width: rem(190);

        font-size: rem(15);
        color: $white;
        text-align: left;

        @include media-breakpoint-up(sm) {
          width: rem(300);
        }

        &__header {
          font-size: rem(15);
          font-weight: 500;
          white-space: nowrap;
        }

        &__description {
          margin-top: rem(5);
          font-size: rem(13);
        }
      }
    }
  }
}
